import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';

import BrewerySection from '../components/brewery/brewery-section';

export const query = graphql`
	{
		prismicBreweryPage {
			prismicId
			data {
				skyline_section_heading {
					text
				}
				skyline_section_blurb {
					html
				}
				lounge_section_heading {
					text
				}
				lounge_section_blurb {
					html
				}
				fridge_section_heading {
					text
				}
				fridge_section_blurb {
					html
				}
				fridge_image {
					url
				}
			}
		}
	}
`;

const PageTemplate = ({ data }) => (
	<Layout>
		<SEO title="Brewery" />
		<BrewerySection
			sky_title={data.prismicBreweryPage.data.skyline_section_heading.text}
			sky_content={data.prismicBreweryPage.data.skyline_section_blurb.html}
			lounge_title={data.prismicBreweryPage.data.lounge_section_heading.text}
			lounge_content={data.prismicBreweryPage.data.lounge_section_blurb.html}
			fridge_title={data.prismicBreweryPage.data.fridge_section_heading.text}
			fridge_content={data.prismicBreweryPage.data.fridge_section_blurb.html}
			fridge_image={data.prismicBreweryPage.data.fridge_image.url}
		></BrewerySection>
	</Layout>
);

export default withPreview(PageTemplate);
