import React from 'react';
import styled from 'styled-components';

import breweryBg from './images/brewery-bg-short2.jpg';

const StyledSection = styled.section`
	background-image: url(${breweryBg});
	background-size: 100%;
	/*min-height: 3340px;*/
	min-height: 259vw;
	padding-top: 250px;
	@media (max-width: 1000px) {
		padding-top: 31vw;
	}
	h1,
	h2 {
		font-size: 2rem;
		margin-bottom: 1rem;
	}
	p {
		font-size: 1.25rem;
	}
	@media (max-width: 1000px) {
		background-size: 209%;
		background-position: 8% 0px;
		min-height: 520vw;
	}
	.open {
		position: absolute;
		/*top: 300px;
		left: 64px;
		width: 720px;*/
		h1 {
			font-size: 2.5rem;
			margin-bottom: 1rem;
		}
		top: 23vw;
		left: 4vw;
		width: 62vw;
		@media (max-width: 1400px) {
			top: 23vw;
			left: 4vw;
			width: 62vw;
		}
		@media (max-width: 1000px) {
			position: relative;
			width: 100vw;
			left: unset;
			right: unset;
			padding: 0 1rem;
			top: 0;
			h1 {
				font-size: 3rem;
			}
			p {
				font-size: 1.25rem;
			}
		}
		@media (max-width: 500px) {
			h1 {
				font-size: 2rem;
			}
			p {
				font-size: 1rem;
			}
		}
	}
	.panel-one,
	.panel-two,
	.stats {
		position: absolute;
		background-color: rgba(0, 0, 0, 0.7);
		color: white;
		padding: 2rem;
		@media (max-width: 1250px) {
			p {
				font-size: 1rem;
			}
		}
	}
	.panel-one {
		/*left: 120px;
		top: 1417px;
		width: 800px;*/
		left: 9vw;
		top: 116vw;
		width: 65vw;
		@media (max-width: 1400px) {
			left: 9vw;
			top: 109vw;
			width: 65vw;
		}
		@media (max-width: 1000px) {
			position: relative;
			width: 100vw;
			left: unset;
			right: unset;
			border-left-width: 0;
			border-right-width: 0;
			padding: 1rem;
			background-color: rgba(0, 0, 0, 0.85);
			top: 54vw;
		}
	}
	.panel-two {
		/*left: 640px;
		top: 2000px;
		width: 570px;*/
		left: 47vw;
		top: 171vw;
		width: 50vw;
		@media (max-width: 1400px) {
			left: 47vw;
			top: 154vw;
			width: 50vw;
		}
		@media (max-width: 1000px) {
			position: relative;
			width: 100vw;
			left: unset;
			right: unset;
			border-left-width: 0;
			border-right-width: 0;
			padding: 1rem;
			background-color: rgba(0, 0, 0, 0.85);
			top: 96vw;
		}
	}
	.video-link {
		display: block;
		position: absolute;
		left: 53vw;
		top: 200vw;
		width: 10vw;
		height: 10vw;
		@media (max-width: 1000px) {
			display: none;
		}
	}
`;

export default ({
	sky_title,
	sky_content,
	lounge_title,
	lounge_content,
	fridge_title,
	fridge_content,
}) => (
	<StyledSection>
		<div className="open">
			<h1>{sky_title}</h1>
			<div dangerouslySetInnerHTML={{ __html: sky_content }} />
		</div>
		<div className="panel-one has-border">
			<h2>{lounge_title}</h2>
			<div dangerouslySetInnerHTML={{ __html: lounge_content }} />
		</div>
		<div className="panel-two has-border">
			<h2>{fridge_title}</h2>
			<div dangerouslySetInnerHTML={{ __html: fridge_content }} />
		</div>
		<a
			className="video-link"
			href="https://www.youtube.com/watch?v=N51GkbICgxw&t=13s"
			target="_blank"
			rel="noopener noreferrer"
		></a>
	</StyledSection>
);
